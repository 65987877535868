body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Gotham Light";
  src: url("/fonts/GothamLight.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham Book";
  src: url("/fonts/GothamBook.ttf") format("truetype");
}

@font-face {
  font-family: "Gotham Bold";
  src: url("/fonts/GothamBold.ttf") format("truetype");
}

@font-face {
  font-family: "Whitney Book";
  src: url("/fonts/Whitney-Book-Font.otf") format("opentype");
}

.headFootContainer {
  max-width: 960px;
  margin: 0 auto;
  float: none;
}

.textContainerOuter {
  padding: 40px 25px;
}
.textContainer {
  max-width: 720px;
  margin: 0 auto;
  float: none;
}
