.ant-carousel .slick-slide {
  background: #000000;
  overflow: hidden;
}

.ant-carousel .slick-slide .bgDiv {
  background-origin: padding-box;
  background-position: 50% 50%;
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-carousel .slick-slide .bgDiv .segment {
  padding-left: 25;
  padding-right: 25;
  padding-top: 25;
  padding-bottom: 10;
  max-width: 960;
}

.ant-carousel .slick-slide .bgDiv .text {
  position: absolute;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(0%, -50%);
}

.ant-carousel .slick-slide h1 {
  font-family: -apple-system, "Gotham Light";
  text-shadow: 1px 1px 2px #000000;
  font-size: 2.5em;
  margin-bottom: -0.25em;
  color: #fff;
}

.ant-carousel .slick-slide h2 {
  font-family: -apple-system, "Gotham Bold";
  max-width: 375px;
  font-size: 1.25em;
  line-height: 0.95em;
  margin-bottom: 0em;
  font-variant-caps: all-small-caps;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .ant-carousel .slick-slide .bgDiv {
    height: 300px;
  }
  .ant-carousel .slick-slide .bgDiv .text {
    max-width: 960px;
  }
}

@media only screen and (min-width: 600px) {
  .ant-carousel .slick-slide .bgDiv {
    height: 500px;
  }
  .ant-carousel .slick-slide .bgDiv .text {
    max-width: 960px;
  }
}

.flex-grid {
  display: flex;
  justify-content: space-between;
}

.flex-grid-thirds {
  width: 32%;
}

@media (max-width: 400px) {
  .flex-grid {
    display: block;
  }
  .flex-grid-thirds {
    width: 100%;
  }
}

.ant-card {
  height: 100%;
}
