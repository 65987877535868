.logo {
  width: 220px;
  height: 31px;
  float: left;
}

.ant-layout-footer a {
  color: hsla(0, 0%, 100%, 0.5);
}

.ant-layout-footer a:hover {
  color: hsla(0, 0%, 100%, 1);
}
